<template>
  <div class="container">
    <span class="goods-name">{{ goodsName }}</span>
    <div class="main">
      <img class="cover" :src="goodsCover" alt="cover">
      <div class="footer">
        <img class="avatar" :src="userAvatar" alt="avatar">
        <span class="club">{{ clubName }}</span>
        <img class="icon" src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018a4571-ba3c-f13f-00a5-fae855773d4c?x-oss-process=style/origin" alt="avatar">
        <div class="content">{{ subTitle }}</div>
      </div>
    </div>
    <div class="mask"></div>
    <img class="qrcode" :src="qrcode">
  </div>  
</template>

<script>
import $fetch from '@/utils/fetch.js';
export default {
  name: "goods-poster",
  data() {
    return {
      clubId: "",
      clubName: "",
      goodsCover: "",
      userAvatar: "",
      goodsId: "",
      goodsName: "",
      subTitle: "",
      qrcode: "",
      auth: "",
      goodsPath: "",
    }
  },
  created() {
    const { clubId, clubName, goodsCover, userAvatar, goodsId, goodsName, subTitle, auth, goodsPath } = this.$route.query;
    this.clubId = clubId;
    this.goodsCover = goodsCover;
    this.userAvatar= userAvatar;
    this.clubName = clubName;
    this.goodsId = goodsId;
    this.goodsName = goodsName;
    this.subTitle= subTitle;
    this.auth = auth;
    this.goodsPath = goodsPath;
    this.getWechatCode();
  },
  methods: {
    /** 参数缩短 */ 
    async handleShortenLink() {
      const { clubId, goodsId, auth, goodsPath } = this;
      const { result }  = await $fetch.fetch('xx.cumen.equip.v1.EquipService/ShortenString',
        { action: 1, val: `?clubId=${ clubId }&goodsId=${ goodsId }&goodsPath=${ encodeURIComponent(goodsPath) }` },
        { Authorization: auth }
      );
      return result;
    },
    async getWechatCode() {
      const _ = this;
      const shortLink = await this.handleShortenLink();
      $fetch
        .post("GetWeixinQRCode", {
          page: 'packages/goods/pages/club/index',
          scene: shortLink,
          width: 80,
          is_hyaline: true,
        })
        .then((code) => {
          _.qrcode = "data:image/jpeg;base64, " + code.image;
        });
    },
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";
$multipleCount: 1;

.container {
  position: relative;
  width: 100%;
  height: 201.6vw;
  background: url('https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018a4538-6258-1382-e1e1-6529a72e0f23?x-oss-process=style/origin') no-repeat center top / cover;

  > .goods-name {
    @include ellipsis;
    position: absolute;
    top: px2rem(212 * $multipleCount);
    left: 50%;
    transform: translateX(-50%);
    width: px2rem(638 * $multipleCount);
    height: px2rem(56 * $multipleCount);
    padding: 0 px2rem(24 * $multipleCount);
    font-size: px2rem(40 * $multipleCount);
    color: #fff;
    text-align: center;
    font-family: Alibaba PuHuiTi Heavy, Alibaba PuHuiTi;
  }

  > .main {
    position: absolute;
    top: px2rem(302 * $multipleCount);
    left: px2rem(32 * $multipleCount);
    width: px2rem(686 * $multipleCount);
    height: px2rem(1018 * $multipleCount);

    > .cover {
      width: 100%;
      height: px2rem(686 * $multipleCount);
    }

    > .footer {
      position: relative;
      width: 100%;
      height: px2rem(336 * $multipleCount);
      background: url('https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018a4538-6259-af0a-5898-93555521df2a?x-oss-process=style/origin') no-repeat center top / cover;
      border-bottom-left-radius: px2rem(28 * $multipleCount);
      border-bottom-right-radius: px2rem(28 * $multipleCount);

      > .avatar {
        position: absolute;
        left: px2rem(32 * $multipleCount);
        width: px2rem(112 * $multipleCount);
        height: px2rem(112 * $multipleCount);
        border-radius: 50%;
      }

      > .club {
        @include ellipsis;
        display: inline;
        position: absolute;
        top: px2rem(48 * $multipleCount);
        left: px2rem(160 * $multipleCount);
        padding: 0 px2rem(48 * $multipleCount) 0 px2rem(16 * $multipleCount);
        max-width: px2rem(416 * $multipleCount);
        height: px2rem(48 * $multipleCount);
        line-height: px2rem(48 * $multipleCount);
        font-size: px2rem(32 * $multipleCount);
        font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
        background: linear-gradient(90deg, #07E6FF 0%, #72FF07 99%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        border-bottom: 1px solid rgba(63, 243, 124, .3);

        &::after {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          width: px2rem(32 * $multipleCount);
          height: px2rem(32 * $multipleCount);
          background: url('https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018a456f-6c5b-a937-156c-3e3292bdb245?x-oss-process=style/origin') no-repeat left top / cover;
        }
      }

      > .icon {
        position: absolute;
        top: px2rem(132 * $multipleCount);
        left: px2rem(40 * $multipleCount);
        width: px2rem(164 * $multipleCount);
        height: px2rem(48 * $multipleCount);
      }

      > .content {
        @include multi-ellipsis(3);
        position: absolute;
        top: px2rem(136 * $multipleCount);
        left: px2rem(42 * $multipleCount);
        width: px2rem(602 * $multipleCount);
        height: px2rem(144 * $multipleCount);
        line-height: px2rem(48 * $multipleCount);
        color: #fff;
        font-size: px2rem(28 * $multipleCount);
        text-indent: px2rem(164 * $multipleCount);
      }
    }
  }

  > .mask {
    position: absolute;
    top: px2rem(304 * $multipleCount);
    left: px2rem(32 * $multipleCount);
    width: px2rem(686 * $multipleCount);
    height: px2rem(1020 * $multipleCount);
    background: url('https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018a4970-950c-4594-de2a-a15fdf8cc7c4?x-oss-process=style/origin') no-repeat center top / cover;
  }

  > .qrcode {
    position: absolute;
    bottom: px2rem(60 * $multipleCount);
    right: px2rem(36 * $multipleCount);
    width: px2rem(112 * $multipleCount);
    height: px2rem(112 * $multipleCount);
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: px2rem(8 * $multipleCount);
  }
}
</style>
